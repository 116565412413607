import { call, take, takeEvery } from 'redux-saga/effects';
import qs from 'query-string';
import {
  dispatchRequestParentUrl,
  dispatchRedirect,
} from 'utils/message/postMessageCenter';

export const composeRedirectUrl = ({ merchantId, parentUrl }) =>
  `${window.location.origin}/api/tiktok/connect?${qs.stringify({
    merchant_id: merchantId,
    redirect_url: parentUrl,
  })}`;

export function* connectTt4bSaga({ payload: { merchantId } }) {
  yield call(dispatchRequestParentUrl);
  const {
    payload: { url: parentUrl },
  } = yield take('REQUEST_PARENT_URL_RESPONSE');
  const url = yield call(composeRedirectUrl, { merchantId, parentUrl });
  yield call(dispatchRedirect, {
    url,
  });
}

export default function* postMessageSagas() {
  yield takeEvery('CONNECT_TT4B', connectTt4bSaga);
}
