const main = {
  'Channel Integration': '第三方服务',
  TikTok: 'TikTok',
  'TikTok for Business Title': 'TikTok 商业扩充套件',
  'TikTok for Business Description':
    '在增长最快的社交网络上，将您的产品展示在活跃的受众面前，让更多人喜爱您的产品',
  'Install now': '立即安装',
  'Learn more': '了解详情',
  'Connected TikTok Account': '已连接的 TikTok 帐号',
  'Basic setting': '基础设定',
  'TikTok for Business Account Title': 'TikTok for Business 帐号',
  'TikTok for Business Account Description':
    '连接到您当前 TikTok 帐号下，拥有管理员权限的 Business 帐号',
  'Go to Business Center': '前往 Business Center',
  'TikTok Catalog': 'TikTok Catalog',
  'TikTok Catalog Description':
    '管理您想在 TikTok 推广或销售的所有商品有关的资讯',
  Advertising: '广告投放',
  'Advertising Account Title': '广告帐号',
  'Advertising Account Description': '连结收取您广告费用的广告帐号',
  'TikTok Pixel': 'TikTok Pixel',
  'TikTok Pixel Description':
    'Pixel 像素会在您的线上商店追踪顾客行为。该资料可以帮助您改善行销能力',
  'Disconnect Current Account': '取消连接当前帐户',
  'Disconnect Popup Title': '确认解除连接 TikTok 帐号？',
  'Disconnect Popup Description':
    '解除连接后，将不可使用 TikTok for Business 全部功能？',
  'Disconnect Popup Confirm': '确认解除连接',
  Cancel: '取消',
  'Connection Error Alert': '连接错误，请检查资产状态并重新连接',
  'LEARN MORE LINK':
    'https://support.shoplineapp.com/hc/zh-cn/articles/4407185010329',
  Email: '邮箱: {{email}}',
  Connect: '连接',
  'TikTok AccessToken Invalid Alert':
    '您的资产权限已失效，无法投放广告；请按下「连接」重新连接您的 TikTok for Business 资产，方能再次顺利投放 TikTok 广告',
  'TikTok for Business Account Hint':
    '暂未连接，若您欲投放广告请先按下连接资产按钮选择或创建 TikTok For Business 帐号',
  'TikTok Pixel Hint':
    '暂未连接，若您欲投放广告请先按下连接资产按钮选择或创建 TikTok Pixel',
  'TikTok Ads Hint':
    '暂未连接，若您欲投放广告请先按下连接资产按钮选择或创建广告帐号',
  'TikTok Catalog Hint':
    '暂未连接，若您欲投放广告请先按下连接资产按钮选择或创建商品目录',
  'Edit Assets': '编辑资产',
  'TikTok Update Success':
    '已成功更新您的 TikTok 资产！\n可在基础设定、广告投放中管理您的资产',
};

export default main;
