import {
  GET_INITIAL_STATE_REQUEST,
  GET_INITIAL_STATE_SUCCESS,
  GET_INITIAL_STATE_FAIL,
} from '../actions/getInitialState';

import {
  GET_MERCHANT_INFO_FAIL,
  GET_MERCHANT_INFO_SUCCESS,
  GET_MERCHANT_INFO_REQUEST,
} from '../actions/getMerchantInfo';

import { DISCONNECT_TIKTOK_SUCCESS } from '../actions/disconnectTiktok';

const initState = {
  isLoadingInitState: true,
  merchantData: {},
  merchantSetting: {},
  staff: {},
  tiktokMerchantConnected: false,

  isLoadingMerchantInfo: false,
  merchantTiktokInfo: null,

  error: null,
};

const merchant = (state = initState, action) => {
  switch (action.type) {
    case GET_INITIAL_STATE_REQUEST: {
      return { ...state, error: null, isLoadingInitState: true };
    }
    case GET_INITIAL_STATE_SUCCESS: {
      const { data } = action.payload;
      const {
        merchant: merchantData,
        merchantOrdersSetting: merchantSetting,
        tiktokMerchantConnected,
        ...restData
      } = data;
      return {
        ...state,
        error: null,
        isLoadingInitState: false,
        merchantData,
        merchantSetting,
        tiktokMerchantConnected,
        ...restData,
      };
    }
    case GET_MERCHANT_INFO_REQUEST: {
      return { ...state, isLoadingMerchantInfo: true };
    }
    case GET_MERCHANT_INFO_SUCCESS: {
      if (action.payload.data?.status === 'connected')
        return {
          ...state,
          isLoadingMerchantInfo: false,
          merchantTiktokInfo: action.payload.data,
        };
      return {
        ...state,
        isLoadingMerchantInfo: false,
        tiktokMerchantConnected: false,
      };
    }
    case GET_INITIAL_STATE_FAIL:
    case GET_MERCHANT_INFO_FAIL: {
      const { error } = action.payload;
      const newError = {
        error: error.error || 'Something Went Wrong, Please Try Again Later',
        errorDetail: error.errorDetail || {},
      };
      return {
        ...state,
        isLoadingInitState: false,
        isLoadingMerchantInfo: false,
        error: newError,
      };
    }

    case DISCONNECT_TIKTOK_SUCCESS: {
      return {
        ...state,
        merchantTiktokInfo: null,
        tiktokMerchantConnected: false,
      };
    }
    default:
      return state;
  }
};

export default merchant;
