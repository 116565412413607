import { useState, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Tabs, Button, Icons, media } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import NewWindow from 'components/NewWindow';
import { Section } from 'components/SectionDisplay';
import ButtonDropdown from 'components/ButtonDropdown';
import { withProps } from 'utils/recompose';
import SettingCard from './SettingCard';
import { getMerchantInfo } from '../../../stores/merchant/actions/getMerchantInfo';

const StyledSettingCard = styled(SettingCard)`
  &:not(:first-child) {
    border-top: solid 1px;
    border-color: ${({ theme }) => theme.colors.SKY_DARK};
    padding-top: 1.25rem;
  }
`;

const buttonMixin = css`
  flex-shrink: 0;
  ${media.mobile`
    margin-top: 0.5rem;
    width: 100%;
  `}
`;
const DefaultAnchorButton = withProps({ as: 'a' })(Button.Default);
const PrimaryAnchorButton = withProps({ as: 'a' })(Button.Primary);

const StyledPrimaryButton = styled(PrimaryAnchorButton)`
  ${buttonMixin}
`;

const StyledDefaultButton = styled(DefaultAnchorButton)`
  ${buttonMixin}
`;

const SettingCardList = styled.div``;

const BASIC_TAB = 'basic';
const ADVERTISING_TAB = 'advertising';

const SettingSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('main');
  const [currentTabKey, setCurrentTabKey] = useState(BASIC_TAB);
  const [isOpenReconnectPopup, setOpenReconnectPopup] = useState(false);
  const { advertiser, businessCenter, catalog, pixel, merchantId, synced } =
    useSelector((state) => state.merchant.merchantTiktokInfo);

  const tabsConfig = useMemo(
    () => [
      { value: BASIC_TAB, label: t('Basic setting') },
      { value: ADVERTISING_TAB, label: t('Advertising') },
    ],
    [t],
  );

  const ReconnectPopup = (
    <NewWindow
      url={`/api/tiktok/connect?merchant_id=${merchantId}&redirect_url=/${merchantId}?locale=zh-hant&close_method=close_from_tiktok`}
      onUnload={() => {
        setOpenReconnectPopup(false);
        if (merchantId) dispatch(getMerchantInfo({ merchantId, reload: true }));
      }}
      center="screen"
      features={{ width: 820, height: 830 }}
    ></NewWindow>
  );

  const tabSetting = useMemo(
    () => ({
      [BASIC_TAB]: [
        {
          title: t('TikTok for Business Account Title'),
          description: t('TikTok for Business Account Description'),
          profileName: businessCenter?.name,
          profileId: businessCenter?.id,
          profileHint: t('TikTok for Business Account Hint'),
          profileRightSection: businessCenter?.id ? (
            <StyledDefaultButton
              Icon={Icons.Outline.Link}
              href={`https://business.tiktok.com/manage/account?org_id=${businessCenter?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {t('Go to Business Center')}
            </StyledDefaultButton>
          ) : (
            <StyledPrimaryButton onClick={() => setOpenReconnectPopup(true)}>
              {t('Connect')}
            </StyledPrimaryButton>
          ),
          titleRightSection: businessCenter?.id && (
            <ButtonDropdown
              menuAlign="right"
              placeHolder={t('Manage Settings')}
              iconDirection="RIGHT"
              options={[
                {
                  value: 'edit',
                  key: 'edit',
                  label: t('Edit Assets'),
                  Icon: Icons.Outline.Edit,
                },
              ]}
              value={[]}
              onChange={() => setOpenReconnectPopup(true)}
            />
          ),
          synced,
        },
        {
          title: t('TikTok Catalog'),
          description: t('TikTok Catalog Description'),
          profileName: catalog?.name,
          profileId: catalog?.id,
          profileHint: t('TikTok Catalog Hint'),
          profileRightSection: catalog?.id ? (
            <StyledDefaultButton onClick={() => setOpenReconnectPopup(true)}>
              {t('Edit Assets')}
            </StyledDefaultButton>
          ) : (
            <StyledPrimaryButton onClick={() => setOpenReconnectPopup(true)}>
              {t('Connect')}
            </StyledPrimaryButton>
          ),
          synced,
        },
      ],
      [ADVERTISING_TAB]: [
        {
          title: t('Advertising Account Title'),
          description: t('Advertising Account Description'),
          profileName: advertiser?.name,
          profileId: advertiser?.id,
          profileHint: t('TikTok Ads Hint'),
          profileRightSection: advertiser?.id ? (
            <StyledDefaultButton onClick={() => setOpenReconnectPopup(true)}>
              {t('Edit Assets')}
            </StyledDefaultButton>
          ) : (
            <StyledPrimaryButton onClick={() => setOpenReconnectPopup(true)}>
              {t('Connect')}
            </StyledPrimaryButton>
          ),
          synced,
        },
        {
          title: t('TikTok Pixel'),
          description: t('TikTok Pixel Description'),
          profileName: pixel?.name,
          profileId: pixel?.code,
          profileHint: t('TikTok Pixel Hint'),
          profileRightSection: pixel?.code ? (
            <StyledDefaultButton onClick={() => setOpenReconnectPopup(true)}>
              {t('Edit Assets')}
            </StyledDefaultButton>
          ) : (
            <StyledPrimaryButton onClick={() => setOpenReconnectPopup(true)}>
              {t('Connect')}
            </StyledPrimaryButton>
          ),
          synced,
        },
      ],
    }),
    [advertiser, businessCenter, catalog, pixel, t, synced],
  );

  return (
    <Section>
      <Tabs.TabsHeader
        backgroundColor="SNOW_200"
        renderTabs={(Tab) =>
          tabsConfig.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              isActive={currentTabKey === tab.value}
              onClick={() => setCurrentTabKey(tab.value)}
              isDisabled={false}
              isError={false}
            />
          ))
        }
      />
      <SettingCardList>
        {tabSetting[currentTabKey].map((settingConfig, index) => (
          <StyledSettingCard key={index} {...settingConfig} />
        ))}
      </SettingCardList>
      {isOpenReconnectPopup && ReconnectPopup}
    </Section>
  );
};

export default memo(SettingSection);
