const main = {
  'Channel Integration': 'Channel Integration',
  TikTok: 'TikTok',
  'TikTok for Business Title': 'TikTok for Business',
  'TikTok for Business Description':
    'Get your products in front of a very engaged audience on one of the fastest growing social networks.',
  'Install now': 'Install now',
  'Learn more': 'Learn more',
  'Connected TikTok Account': 'Connected TikTok Account',
  'Basic setting': 'Basic setting',
  'TikTok for Business Account Title': 'TikTok for Business Account',
  'TikTok for Business Account Description':
    'Connect to your current TikTok account, a Business account with administrator rights',
  'Go to Business Center': 'Go to Business Center',
  'TikTok Catalog': 'TikTok Catalog',
  'TikTok Catalog Description':
    'Manage information about all the products you want to promote or sell on TikTok',
  Advertising: 'Advertising',
  'Advertising Account Title': 'Advertising Account',
  'Advertising Account Description':
    'Link to the advertising account that charges you for advertising',
  'TikTok Pixel': 'TikTok Pixel',
  'TikTok Pixel Description':
    'Pixel will track customer behavior in your online store. This information can help you improve your marketing capabilities.',
  'Disconnect Current Account': 'Disconnect Current Account',
  'Disconnect Popup Title': 'Confirm to unlink TikTok Account?',
  'Disconnect Popup Description':
    'Will all TikTok for Business functions be unavailable after disconnection?',
  'Disconnect Popup Confirm': 'Confirm to disconnect',
  Cancel: 'Cancel',
  'Connection Error Alert':
    'Connection error, please check your assets status and reconnect.',
  'LEARN MORE LINK':
    'https://support.shoplineapp.com/hc/en-us/articles/4407185010329',
  Email: 'Email Address: {{email}}',
  Connect: 'Connect',
  'TikTok AccessToken Invalid Alert':
    'Your assets permission has expired and cannot serve ads; please press "Connect" button to reconnect your TikTok for Business assets for serving TikTok ads again.',
  'TikTok for Business Account Hint':
    'Not connected yet, if you want to advertise, please press the "Connect" button to select or create a TikTok For Business Account',
  'TikTok Pixel Hint':
    'Not connected yet, if you want to advertise, please press the "Connect" button to select or create a TikTok Pixel',
  'TikTok Ads Hint':
    'Not connected yet, if you want to advertise, please press the "Connect" button to select or create an Ads Account',
  'TikTok Catalog Hint':
    'Not connected yet, if you want to advertise, please press the "Connect" button to select or create a Product Catalog',
  'Edit Assets': 'Edit Assets',
  'TikTok Update Success':
    'Successfully updated your TikTok assets!\nManage your assets in "Basic Setting", "Advertising" tabs.',
};

export default main;
