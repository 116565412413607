import PropTypes from 'prop-types';
import { memo } from 'react';
import styled from 'styled-components';
import { Title } from 'components/SectionDisplay';
import TiktokLogo from './logo.svg';

const TitleContainer = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const StyledLogo = styled.img`
  width: 1.3125rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

const TiktokTitle = ({ title }) => (
  <TitleContainer>
    <StyledLogo src={TiktokLogo} alt="tiktok logo" />
    <Title>{title}</Title>
  </TitleContainer>
);

TiktokTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default memo(TiktokTitle);
