import { combineReducers } from 'redux';
import merchant from './merchant/reducers';
import toasts from './toasts/reducers';

const createRootReducer = () =>
  combineReducers({
    merchant,
    toasts,
  });

export default createRootReducer;
