import PropTypes from 'prop-types';
import { memo } from 'react';
import styled from 'styled-components';
import { Avatar } from '@shopline/dashboard-ui';
import TextLabel from 'components/TextLabel';
import image from './avatar.png';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AvatarImage = styled(Avatar.Image)`
  width: 48px;
  height: 48px;
  margin-right: 8px;
  box-shadow: inset 0px -8.61538px 25.8462px rgba(0, 0, 0, 0.04);
  flex-shrink: 0;
`;

const Hint = styled(TextLabel)``;

const Profile = ({ hint, className }) => (
  <Container className={className}>
    <AvatarImage url={image} alt="avatar image" />
    <Hint fontType="Body" color="INK_400">
      {hint}
    </Hint>
  </Container>
);

Profile.propTypes = {
  hint: PropTypes.string.isRequired,
};

export default memo(Profile);
