import { all, call, spawn } from 'redux-saga/effects';
import postMessageSagas from './postMessage.saga';

const sagas = [
  // list sagas
  postMessageSagas,
];

export default function* rootSaga() {
  yield all(
    sagas.map((saga) =>
      spawn(function* inlinedSaga() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e); // eslint-disable-line no-console
          }
        }
      }),
    ),
  );
}
