import { useState, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Icons, Button, Alert, media } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

import { disconnectTiktok } from 'stores/merchant/actions/disconnectTiktok';

import IconDropdown from 'components/IconDropdown';
import { Section } from 'components/SectionDisplay';
import TiktokTitle from '../components/TiktokTitle';
import Profile from '../components/Profile';
import DisconnectPopup from '../components/DisconnectPopup';

const ProfileContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const buttonMixin = css`
  ${media.mobile`
    margin-top: 0.5rem;
    width: 100%;
  `}
`;

const StyledPrimaryButton = styled(Button.Primary)`
  ${buttonMixin}
`;
const StyledDestructiveAlert = styled(Alert.Destructive)`
  width: 100%;
`;

const StyledIconDropdown = styled(IconDropdown)`
  flex-shrink: 0;
`;

const ProfileSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('main');
  const [isOpenDisconnectPopup, setOpenDisconnectPopup] = useState(false);

  const { synced, userProfile, merchantId } = useSelector(
    (state) => state.merchant.merchantTiktokInfo,
  );

  const onInstallClicked = useCallback(() => {
    dispatch({
      type: 'CONNECT_TT4B',
      payload: { merchantId },
    });
  }, [dispatch, merchantId]);

  return (
    <Section>
      <TiktokTitle title={t('Connected TikTok Account')} />
      <ProfileContainer>
        <Profile
          name={userProfile?.display_name}
          description={t('Email', { email: userProfile?.email })}
        />
        {synced ? (
          <StyledIconDropdown
            menuAlign="right"
            Icon={Icons.Solid.StraightDots}
            iconDirection="RIGHT"
            options={[
              {
                value: 'disconnect',
                key: 'disconnect',
                label: t('Disconnect Current Account'),
                color: 'RED_600',
                Icon: Icons.Outline.Unlink,
                iconDirection: 'BOTTOM',
              },
            ]}
            value={[]}
            onChange={() => setOpenDisconnectPopup(true)}
          />
        ) : (
          <StyledPrimaryButton onClick={onInstallClicked}>
            {t('Connect')}
          </StyledPrimaryButton>
        )}
      </ProfileContainer>
      {!synced && (
        <StyledDestructiveAlert>
          {t('TikTok AccessToken Invalid Alert')}
        </StyledDestructiveAlert>
      )}
      {isOpenDisconnectPopup && (
        <DisconnectPopup
          onClose={() => setOpenDisconnectPopup(false)}
          onSubmit={async () => {
            await dispatch(disconnectTiktok());
            setOpenDisconnectPopup(false);
          }}
        />
      )}
    </Section>
  );
};

export default memo(ProfileSection);
