const ROUTE_CHANGE = 'ROUTE_CHANGE';
const ROUTE_SEARCH_CHANGE = 'ROUTE_SEARCH_CHANGE';
const UPDATE_LIST_SEARCH = 'UPDATE_LIST_SEARCH';
const REDIRECT = 'REDIRECT';
const REQUEST_PARENT_URL = 'REQUEST_PARENT_URL';

const postMessageCenter = () => {
  const dispatchMessage = (payload = {}) => {
    const { type } = payload;
    switch (type) {
      case ROUTE_CHANGE:
      case ROUTE_SEARCH_CHANGE:
      case UPDATE_LIST_SEARCH:
      case REDIRECT:
      case REQUEST_PARENT_URL:
        window.parent.postMessage(payload, '*');
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(
          'Please add your message type to utils/postMessageCenter first',
        );
        break;
    }
  };

  return { dispatchMessage };
};

const messageCenter = postMessageCenter();

const dispatchUpdateListSearch = ({ parameters }) =>
  messageCenter.dispatchMessage({
    type: UPDATE_LIST_SEARCH,
    parameters,
  });

const dispatchRouteChange = ({ pathname }) =>
  messageCenter.dispatchMessage({
    type: ROUTE_CHANGE,
    pathname,
  });

const dispatchRouteSearchChange = ({ search }) =>
  messageCenter.dispatchMessage({
    type: ROUTE_SEARCH_CHANGE,
    search,
  });

const dispatchRedirect = ({ url }) =>
  messageCenter.dispatchMessage({
    type: REDIRECT,
    url,
  });

const dispatchRequestParentUrl = () =>
  messageCenter.dispatchMessage({
    type: REQUEST_PARENT_URL,
  });

export default messageCenter;
export {
  dispatchUpdateListSearch,
  dispatchRouteChange,
  dispatchRouteSearchChange,
  dispatchRedirect,
  dispatchRequestParentUrl,
};
