import { BrowserRouter as Router, Route, matchPath } from 'react-router-dom';
import MainRoute from './Main';
import ToastList from './ToastList';
import AppBase from './AppBase';

const matchValidPath = (targetPath) =>
  matchPath(targetPath, {
    path: '/:merchantId',
  });

const FeatureRoute = () => {
  const matchResult = matchValidPath(window.location.pathname);

  if (!matchResult) return <div>Invalid Route</div>;
  return (
    <Router basename={`/${matchResult.params.merchantId}`}>
      <AppBase merchantId={matchResult.params.merchantId}>
        <Route exact path="/" component={MainRoute} />
        <Route component={ToastList} />
      </AppBase>
    </Router>
  );
};

export default FeatureRoute;
