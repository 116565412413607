import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, media } from '@shopline/dashboard-ui';
import { Section, Description } from 'components/SectionDisplay';
import { withProps } from 'utils/recompose';
import TiktokTitle from '../components/TiktokTitle';
import image from './image.png';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ContentContainer = styled.div`
  margin-right: 1rem;

  ${media.mobile`
    margin-right: 0;
  `}
`;

const Image = styled.img`
  width: 19.375rem;
  height: 10.6875rem;
  object-fit: cover;
  border-radius: 6px;

  ${media.mobile`
    width: 100%;
    height: auto;
  `}
`;

const ButtonsContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  ${media.mobile`
    display: block;
  `}
`;

const buttonMixin = css`
  ${media.mobile`
    width: 100%;
  `}
`;

const StyledPrimaryButton = styled(Button.Primary)`
  ${buttonMixin}
`;

const DefaultAnchorButton = withProps({ as: 'a' })(Button.Default);

const StyledDefaultButton = styled(DefaultAnchorButton)`
  margin-left: 0.5rem;
  ${media.mobile`
    margin-left: 0;
    margin-top: 0.5rem;
  `}
  ${buttonMixin}
`;

const InstallSection = () => {
  const dispatch = useDispatch();
  const merchantId = useSelector((state) => state.merchant.merchantData.id);
  const { t } = useTranslation('main');

  const onInstallClicked = useCallback(() => {
    dispatch({
      type: 'CONNECT_TT4B',
      payload: { merchantId },
    });
  }, [dispatch, merchantId]);

  return (
    <Section>
      <Container>
        <ContentContainer>
          <TiktokTitle title={t('TikTok for Business Title')} />
          <Description>{t('TikTok for Business Description')}</Description>
          <ButtonsContainer>
            <StyledPrimaryButton onClick={onInstallClicked}>
              {t('Install now')}
            </StyledPrimaryButton>
            <StyledDefaultButton
              href={t('LEARN MORE LINK')}
              target="_blank"
              rel="noreferrer"
            >
              {t('Learn more')}
            </StyledDefaultButton>
          </ButtonsContainer>
        </ContentContainer>
        <Image src={image} alt="tiktok image" />
      </Container>
    </Section>
  );
};

export default memo(InstallSection);
