const main = {
  'Channel Integration': '第三方服務',
  TikTok: 'TikTok',
  'TikTok for Business Title': 'TikTok 商業擴充套件',
  'TikTok for Business Description':
    '在增長最快的社交網絡上，將您的產品展示在活躍的受眾面前，讓更多人喜愛您的產品',
  'Install now': '立即安裝',
  'Learn more': '了解詳情',
  'Connected TikTok Account': '已連接的 TikTok 帳號',
  'Basic setting': '基礎設定',
  'TikTok for Business Account Title': 'TikTok for Business 帳號',
  'TikTok for Business Account Description':
    '連接到您當前 TikTok 帳號下，擁有管理員權限的 Business 帳號',
  'Go to Business Center': '前往 Business Center',
  'TikTok Catalog': 'TikTok Catalog',
  'TikTok Catalog Description':
    '管理您想在 TikTok 推廣或銷售的所有商品有關的資訊',
  Advertising: '廣告投放',
  'Advertising Account Title': '廣告帳號',
  'Advertising Account Description': '連結收取您廣告費用的廣告帳號',
  'TikTok Pixel': 'TikTok Pixel',
  'TikTok Pixel Description':
    'Pixel 像素會在您的線上商店追蹤顧客行為。該資料可以幫助您改善行銷能力',
  'Disconnect Current Account': '取消連接當前帳戶',
  'Disconnect Popup Title': '確認解除連接 TikTok 帳號？',
  'Disconnect Popup Description':
    '解除連接後，將不可使用 TikTok for Business 全部功能？',
  'Disconnect Popup Confirm': '確認解除連接',
  Cancel: '取消',
  'Connection Error Alert': '連接錯誤，請檢查資產狀態並重新連接',
  'LEARN MORE LINK':
    'https://support.shoplineapp.com/hc/zh-tw/articles/4407185010329',
  Email: '電子郵件地址: {{email}}',
  Connect: '連接',
  'TikTok AccessToken Invalid Alert':
    '您的資產權限已失效，無法投放廣告；請按下「連接」重新連接您的 TikTok for Business 資產，方能再次順利投放 TikTok 廣告',
  'TikTok for Business Account Hint':
    '暫未連接，若您欲投放廣告請先按下連接資產按鈕選擇或創建 TikTok For Business 帳號',
  'TikTok Pixel Hint':
    '暫未連接，若您欲投放廣告請先按下連接資產按鈕選擇或創建 TikTok Pixel',
  'TikTok Ads Hint':
    '暫未連接，若您欲投放廣告請先按下連接資產按鈕選擇或創建廣告帳號',
  'TikTok Catalog Hint':
    '暫未連接，若您欲投放廣告請先按下連接資產按鈕選擇或創建商品目錄',
  'Edit Assets': '編輯資產',
  'Manage Settings': '管理設定',
  'TikTok Update Success':
    '已成功更新您的 TikTok 資產！\n可在基礎設定、廣告投放中管理您的資產',
};

export default main;
