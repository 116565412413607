import { useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LoadingSpinner } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import { LANG_CHANGE, REQUEST_PARENT_URL } from 'constants/iframeMessageType';
import { getInitialState } from '../stores/merchant/actions/getInitialState';

const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const defaultPostMessageAction = (type, payload) => ({
  type: `${type}_RESPONSE`,
  payload,
});

const AppBase = ({ merchantId, children }) => {
  const { i18n } = useTranslation();
  const { isLoadingInitState, isLoadingMerchantInfo, error } = useSelector(
    (state) => state.merchant,
  );
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (merchantId) dispatch(getInitialState({ merchantId }));
  }, [dispatch, merchantId]);

  useEffect(() => {
    const handleMessage = (event) => {
      const { type, ...payload } = event.data;
      switch (type) {
        case LANG_CHANGE:
          i18n.changeLanguage(payload.lang);
          break;
        case REQUEST_PARENT_URL:
          dispatch(defaultPostMessageAction(type, payload));
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [i18n, dispatch]);

  if (isLoadingInitState || isLoadingMerchantInfo) {
    return (
      <LoadingWrapper>
        <LoadingSpinner size="36" />
      </LoadingWrapper>
    );
  }
  return <>{error ? error.error : children}</>;
};

export default AppBase;
