import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Page from 'components/Page';
import InstallSection from './InstallSection';
import ProfileSection from './ProfileSection';
import SettingSection from './SettingSection';

const Main = () => {
  const { t } = useTranslation('main');
  const tiktokMerchantConnected = useSelector(
    (state) => state.merchant.tiktokMerchantConnected,
  );
  const breadcrumbs = [t('Channel Integration'), 'TikTok'].map((text) => ({
    id: text,
    label: text,
  }));

  return (
    <Page breadcrumbs={breadcrumbs} titleLeftSection={t('TikTok')}>
      {tiktokMerchantConnected ? (
        <>
          <ProfileSection />
          <SettingSection />
        </>
      ) : (
        <InstallSection />
      )}
    </Page>
  );
};

export default Main;
