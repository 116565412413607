import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup, Button } from '@shopline/dashboard-ui';

const DisconnectPopup = ({ onClose, onSubmit }) => {
  const { t } = useTranslation('main');
  const [isLoading, setLoading] = useState(false);
  return (
    <Popup
      width="30rem"
      onClose={onClose}
      title={t('Disconnect Popup Title')}
      renderFooter={() => (
        <>
          <Button.Default
            height="MEDIUM"
            onClick={isLoading ? undefined : onClose}
          >
            {t('Cancel')}
          </Button.Default>
          <Button.Destructive
            height="LARGE"
            onClick={() => {
              setLoading(true);
              onSubmit();
            }}
            isLoading={isLoading}
          >
            {t('Disconnect Popup Confirm')}
          </Button.Destructive>
        </>
      )}
    >
      {t('Disconnect Popup Description')}
    </Popup>
  );
};

export default memo(DisconnectPopup);
