import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import createRootReducer from './rootReducer';
import rootSaga from './sagas';

import createApi from '../utils/api';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
  const api = createApi();
  const updateApiBaseUrl = (baseUrl) => {
    api.defaults.baseURL = baseUrl;
  };

  const thunkMiddleware = thunk.withExtraArgument({ api, updateApiBaseUrl });

  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || // eslint-disable-line no-underscore-dangle
    compose;
  const middlewares = [thunkMiddleware, sagaMiddleware];
  const store = createStore(
    createRootReducer(),
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
