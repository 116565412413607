import TextLabel from 'components/TextLabel';
import styled from 'styled-components';
import { media } from '@shopline/dashboard-ui';

const Section = styled.div`
  position: relative;
  margin-top: 1rem;
  border-radius: 6px;
  padding: 1.5rem 2rem;
  background-color: ${({ theme }) => theme.colors.SNOW_200};
  box-shadow: 0 0.0625rem 0.1875rem rgb(54 62 81 / 10%);

  ${media.mobile`
    padding: 1rem 1.5rem;
    max-width: calc(100vw - 2rem);
    margin-left: auto;
    margin-right: auto;
  `}
`;
Section.displayName = 'Section';

const Title = (props) => (
  <TextLabel
    fontType="DisplaySmall"
    color="INK_DEFAULT"
    weight="Medium"
    {...props}
  />
);
Title.displayName = 'Title';

const Description = (props) => (
  <TextLabel fontType="Body" color="INK_HOVER" {...props} />
);
Description.displayName = 'Description';

export { Section, Title, Description };
