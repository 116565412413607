import React, { useState } from 'react';
import { ButtonDropdown as UIButtonDropdown } from '@shopline/dashboard-ui';

const ButtonDropdown = ({ onChange, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionChange = (optionValues) => {
    if (onChange) {
      onChange(optionValues);
    }
    handleButtonDropdownClose();
  };

  const handleButtonDropdownClose = () => {
    setIsOpen(false);
  };

  const onButtonDropdownClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <UIButtonDropdown
      {...props}
      isOpen={isOpen}
      onButtonClick={onButtonDropdownClick}
      onSelectorClick={onButtonDropdownClick}
      onClose={handleButtonDropdownClose}
      onChange={handleOptionChange}
    />
  );
};

export default ButtonDropdown;
