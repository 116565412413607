import PropTypes from 'prop-types';
import { memo } from 'react';
import styled from 'styled-components';
import { media } from '@shopline/dashboard-ui';
import TextLabel from 'components/TextLabel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`
    width: 100%;
  `}
`;

const Description = styled(TextLabel)`
  margin-top: 0.25rem;
`;

const Profile = ({ name, description, className }) => (
  <Container className={className}>
    <TextLabel fontType="Body" weight="Medium" color="INK_DEFAULT">
      {name}
    </TextLabel>
    {!!description && (
      <Description fontType="Body" color="INK_600">
        {description}
      </Description>
    )}
  </Container>
);

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default memo(Profile);
