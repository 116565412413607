import PropTypes from 'prop-types';
import { memo } from 'react';
import styled from 'styled-components';
import { media } from '@shopline/dashboard-ui';
import { Title, Description } from 'components/SectionDisplay';
import Profile from '../components/Profile';
import ProfileAlt from '../components/ProfileAlt';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  padding: 0.625rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  ${media.mobile`
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
  `}
`;

const TitleLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0.25rem;
`;

const StyledDescription = styled(Description)`
  margin-bottom: 1.25rem;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  ${media.mobile`
    flex-wrap: wrap;
  `}
`;

const StyledProfile = styled(Profile)`
  margin-right: 0.5rem;
`;

const SettingCard = ({
  title,
  description,
  profileName,
  profileId,
  profileHint,
  profileRightSection,
  titleRightSection,
  className,
  synced,
}) => (
  <Container className={className}>
    <TitleContainer>
      <TitleLeftContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </TitleLeftContainer>
      {synced && titleRightSection}
    </TitleContainer>
    <ProfileContainer>
      {synced && profileId ? (
        <StyledProfile name={profileName} description={`ID: ${profileId}`} />
      ) : (
        <ProfileAlt hint={profileHint} />
      )}
      {synced && profileRightSection}
    </ProfileContainer>
  </Container>
);

SettingCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  profileHint: PropTypes.string.isRequired,
  profileRightSection: PropTypes.element,
  titleRightSection: PropTypes.element,
  synced: PropTypes.bool.isRequired,
};

export default memo(SettingCard);
