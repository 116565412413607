const main = {
  'Channel Integration': 'การเชื่อมต่อช่องทาง',
  TikTok: 'TikTok',
  'TikTok for Business Title': 'TikTok เพื่อธุรกิจ',
  'TikTok for Business Description':
    'แสดงสินค้าของคุณต่อผู้ชมบนเครือข่ายโซเชี่ยลที่เติบโตอย่างรวดเร็วที่สุด',
  'Install now': 'ติดตั้งทันที',
  'Learn more': 'ศึกษาเพิ่มเติม',
  'Connected TikTok Account': 'เชื่อมต่อบัญชี TikTok แล้ว',
  'Basic setting': 'การตั้งค่าเบื้องต้น',
  'TikTok for Business Account Title': 'บัญชี TikTok เพื่อธุรกิจ',
  'TikTok for Business Account Description':
    'เชื่อมต่อกับบัญชี TikTok ปัจจุบันของคุณ บัญชีเพื่อธุรกิจที่มีสิทธิ์ของผู้ดูแลระบบ',
  'Go to Business Center': 'ไปที่ศูนย์ธุรกิจ',
  'TikTok Catalog': 'TikTok Catalog',
  'TikTok Catalog Description':
    'จัดการข้อมูลเกี่ยวกับสินค้าทั้งหมดที่คุณต้องการโปรโมตบน TikTok',
  Advertising: 'โฆษณา',
  'Advertising Account Title': 'บัญชีโฆษณา',
  'Advertising Account Description':
    'เชื่อมโยงไปยังบัญชีโฆษณาที่เรียกเก็บเงินค่าโฆษณาจากคุณ',
  'TikTok Pixel': 'TikTok Pixel',
  'TikTok Pixel Description':
    'พิกเซลจะติดตามพฤติกรรมของลูกค้าในร้านค้าออนไลน์ของคุณ ข้อมูลเหล่านี้ช่วยให้คุณปรับปรุงความสามารถทางการตลาดได้',
  'Disconnect Current Account': 'ระงับการเชื่อมต่อบัญชีปัจจุบัน',
  'Disconnect Popup Title': 'คุณยืนยันที่จะยกเลิกการเชื่อมโยงบัญชี TikTok?',
  'Disconnect Popup Description':
    'ฟังก์ชัน TikTok เพื่อธุรกิจทั้งหมดจะไม่สามารถใช้งานได้หลังจากยกเลิกการเชื่อมต่อหรือไม่?',
  'Disconnect Popup Confirm': 'ยืนยันเพื่อยกเลิกการเชื่อมต่อ',
  Cancel: 'ยกเลิก',
  'Connection Error Alert':
    'เกิดข้อผิดพลาดในการเชื่อมต่อ โปรดตรวจสอบสถานะสินทรัพย์และเชื่อมต่อใหม่',
  'LEARN MORE LINK':
    'https://support.shoplineapp.com/hc/th/articles/4407185010329',
  Email: 'ที่อยู่อีเมล: {{email}}',
};

export default main;
