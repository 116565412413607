import { showUIToast } from 'stores/toasts/actions';

export const DISCONNECT_TIKTOK_SUCCESS = 'DISCONNECT_TIKTOK_SUCCESS';

export const disconnectTiktokSuccess = () => ({
  type: DISCONNECT_TIKTOK_SUCCESS,
});

export const disconnectTiktok = () => async (dispatch, getState, context) => {
  const { api } = context;
  const merchantId = getState().merchant.merchantData.id;

  try {
    await api.post('/tiktok/disconnect', { merchant_id: merchantId });
    dispatch(disconnectTiktokSuccess());
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    dispatch(
      showUIToast({
        type: 'alert',
        title: 'main:Connection Error Alert',
      }),
    );
  }
};
