import { get } from 'lodash-es';
import { showUIToast } from 'stores/toasts/actions';

export const GET_MERCHANT_INFO_REQUEST = 'GET_MERCHANT_INFO_REQUEST';
export const GET_MERCHANT_INFO_SUCCESS = 'GET_MERCHANT_INFO_SUCCESS';
export const GET_MERCHANT_INFO_FAIL = 'GET_MERCHANT_INFO_FAIL';

export const getMerchantInfoRequest = () => ({
  type: GET_MERCHANT_INFO_REQUEST,
});

export const getMerchantInfoSuccess = (data = {}) => ({
  type: GET_MERCHANT_INFO_SUCCESS,
  payload: { data },
});
export const getMerchantInfoFailed = (error) => ({
  type: GET_MERCHANT_INFO_FAIL,
  payload: { error },
});

export const getMerchantInfo =
  ({ merchantId, reload = false }) =>
  async (dispatch, getState, context) => {
    const { api } = context;
    dispatch(getMerchantInfoRequest());
    try {
      const { data } = await api(`/merchants/${merchantId}/info`);
      dispatch(getMerchantInfoSuccess(data));
      if (reload) {
        dispatch(
          showUIToast({
            type: 'success',
            title: 'main:TikTok Update Success',
          }),
        );
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      dispatch(
        showUIToast({
          type: 'alert',
          title: 'main:Connection Error Alert',
        }),
      );
      const errorData = get(e, 'response.data', {});
      dispatch(getMerchantInfoFailed(errorData));
    }
  };
