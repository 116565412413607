const main = {
  'Channel Integration': 'Kênh bán hàng',
  TikTok: 'TikTok',
  'TikTok for Business Title': 'TikTok dành cho doanh nghiệp',
  'TikTok for Business Description':
    'Đưa sản phẩm của bạn đến với khách hàng tương tác trên mạng xã hội phát triển vượt bậc nhất.',
  'Install now': 'Cài đặt ngay',
  'Learn more': 'Tìm hiểu thêm',
  'Connected TikTok Account': 'Đã kết nối tài khoản TikTok',
  'Basic setting': 'Thiết lập cơ bản',
  'TikTok for Business Account Title': 'Tài khoản doanh nghiệp TikTok',
  'TikTok for Business Account Description':
    'Kết nối với tài khoản TikTok hiện tại của bạn, tài khoản doanh nghiệp được cấp quyền quản trị viên.',
  'Go to Business Center': 'Đi tới Trung tâm doanh nghiệp',
  'TikTok Catalog': 'TikTok Catalog',
  'TikTok Catalog Description':
    'Quản lý thông tin tất cả các sản phẩm bạn muốn quảng cáo hoặc bán trên TikTok.',
  Advertising: 'Quảng cáo',
  'Advertising Account Title': 'Tài khoản quảng cáo',
  'Advertising Account Description':
    'Liên kết với tài khoản tính phí quảng cáo của bạn',
  'TikTok Pixel': 'TikTok Pixel',
  'TikTok Pixel Description':
    'Pixel sẽ theo dõi hành vi khách hàng trên cửa hàng trực tuyến của bạn. Thông tin này có thể giúp bạn cải thiện khả năng tiếp thị của mình.',
  'Disconnect Current Account': 'Ngắt kết nối tài khoản hiện tại',
  'Disconnect Popup Title': 'Xác nhận hủy liên kết tài khoản TikTok?',
  'Disconnect Popup Description':
    'Sau khi ngắt kết nối, tất cả các chức năng dành cho doanh nghiệp của TikTok sẽ không khả dụng ?',
  'Disconnect Popup Confirm': 'Xác nhận ngắt kết nối',
  Cancel: 'Hủy',
  'Connection Error Alert':
    'Lỗi kết nối, vui lòng kiểm tra trạng thái nội dung và kết nối lại',
  'LEARN MORE LINK':
    'https://support.shoplineapp.com/hc/vi-vn/articles/4407185010329',
  Email: 'Địa chỉ email: {{email}}',
};

export default main;
